import { makeObservableStore } from "../../../shared/utils"
import { runInAction } from "mobx"
import { ArticleCategory } from "../../../shared/types/article"

export interface ArticleCategoryFilterStore {
  category: Set<ArticleCategory>
}

export const articleCategoryFilterStore = makeObservableStore<ArticleCategoryFilterStore>({
  category: new Set(),
})

export function resetArticleFiltersStore() {
  console.log("Reset article category filter store")

  runInAction(() => {
    articleCategoryFilterStore.category.clear()
  })
}
