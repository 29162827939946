import { makeObservableStore } from "../../../shared/utils"
import { Project, ProjectParams } from "../../../shared/types/Project"
import { runInAction } from "mobx"

export interface ProjectListStore {
  isLoading: boolean
  error: string | null
  totalCount: number
  projects: Array<Project>
  params: ProjectParams
}

export const projectListStore = makeObservableStore<ProjectListStore>({
  isLoading: false,
  error: null,
  totalCount: 0,
  projects: [],
  params: {
    page: 1,
    perPage: 9,
  },
})

export function resetProjectListStore() {
  console.log("Reset project list store")

  runInAction(() => {
    projectListStore.isLoading = false
    projectListStore.error = null
    projectListStore.totalCount = 0
    projectListStore.projects = []
    projectListStore.params = {
      page: 1,
      perPage: 9,
    }
  })
}
