import {
  Text,
  Container,
  Box,
  createStyles,
  rem,
  Flex,
  Grid,
  Title,
  Anchor,
  Button, Group,
} from "@mantine/core"
import { IconMail, IconMapPin, IconPhone } from "@tabler/icons-react"
import { DocModalStore } from "../../app/store"
import { FormModalService } from "../../app/service"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  footer: {
    minWidth: 320,
    paddingBottom: 48,
    backgroundColor: theme.colors.gray[9],
  },

  container: {
    paddingTop: "4rem",
    paddingBottom: "4rem",

    [theme.fn.smallerThan("md")]: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },
  },

  phone: {
    color: theme.white,
    fontSize: theme.fontSizes.xl,
    textDecoration: "none",

    "&:hover": {
      color: theme.colors.dark[1],
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    textAlign: "center",
    paddingBottom: theme.spacing.lg,
    color: theme.white,
    marginBottom: theme.spacing.lg,
    borderBottom: `${rem(1)} solid ${theme.colors.dark[5]}`,
  },

  context: {
    gap: 24,

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
    },
  },
}))

export function Footer() {
  const { classes } = useStyles()

  const open = () => DocModalStore.setPrivacyOpened(true)
  const openModal = () => FormModalService.setIsOpened(true, "Обратный звонок", "консультация")

  return (
    <footer className={classes.footer}>
      <Container className={classes.container} size="xl">
        <Grid justify="center">
          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              О компании
            </Title>

            <Flex direction="column" className={classes.context}>
              <Text c="dimmed">
                Стройэкспресс — это динамично развивающаяся строительная компания, специализирующаяся на
                возведении частных домов под ключ в Краснодарском крае. Мы предлагаем комплексные решения в области
                строительства: от разработки проекта до сдачи готового дома.
              </Text>

              <Box c="dimmed">
                <Text>ИП Полегаев Владимир Андреевич</Text>
                <Text>ИНН: 237306774288</Text>
                <Text>ОГРНИП 318237500349221</Text>
              </Box>
            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Контакты
            </Title>

            <Flex direction="column" className={classes.context}>

              <Box>
                <IconMapPin style={{ float: "left", marginRight: 12, marginBottom: 2 }} fontSize={24} color="white"/>
                <Text c="dimmed">Краснодар, Краснодарский край, ул. Московская, д.118,
                  корпус 1.</Text>
              </Box>

              <Group align="center">
                <IconMail color="white"/>
                <Anchor color="white" href="mailto:stroyexpress93@yandex.ru">
                  stroyexpress93@yandex.ru
                </Anchor>
              </Group>

              <Box>
                <Group align="center">
                  <IconPhone color="white"/>
                  <Anchor color="white" href="tel:+79297888819">
                    +7 918 025-95-54
                  </Anchor>
                </Group>
                <Text mt={4} c="dimmed">(9:00 - 22:00 без выходных)</Text>
              </Box>

            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Клиентский отдел
            </Title>

            <Flex direction="column" className={classes.context}>
              <Text c="dimmed">
                Менеджеры и технические специалисты нашей компании с радостью ответят на все ваши вопросы.
              </Text>

              <Button
                radius="lg"
                onClick={openModal}
              >
                Оставить заявку
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>

      <Container size="xl" className={classes.afterFooter} pt={24}>
        <Flex direction="column" gap="xs" align="center">
          <Text underline fz="sm" color="white" sx={{ cursor: "pointer" }} onClick={open}>
            Политика конфиденциальности
          </Text>
          <Text align="center" color="dimmed">
            &copy;&nbsp;2017&nbsp;-&nbsp;{new Date().getFullYear()}&nbsp;«СтройЭкспресс»
          </Text>
          <Text color="dimmed">Все права защищены (Использование материалов с данного сайта запрещено без разрешения
            администрации)</Text>
        </Flex>
      </Container>
    </footer>
  )
}
