import { projectFiltersStore, ProjectFiltersStore, projectListStore, ProjectListStore } from "../store"
import { umbrellaException } from "../../shared/lib/umbrellaException"
import { getProjects, removeProject, getProjectById, createProject, updateProject } from "../../shared/api/project"
import { runInAction } from "mobx"
import { Project, ProjectParams } from "../../shared/types/Project"

class ProjectListServiceImpl {

  async load(controlledPagination?: boolean): Promise<void> {
    try {
      runInAction(() => {
        this.projectListStore.projects = []
        this.projectListStore.isLoading = true
        this.projectListStore.error = null

        if (!controlledPagination) {
          this.projectListStore.params.page = 1
        }
      })

      const params = this.getParams()
      const { data: { totalCount, payload } } = await getProjects(params)

      runInAction(() => {
        this.projectListStore.totalCount = totalCount
        this.projectListStore.projects = payload
        if (!controlledPagination) {
          this.changePage()
        }
      })
    } catch (e) {
      this.projectListStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  async loadMore(): Promise<void> {
    if (this.projectListStore.isLoading) {
      return
    }

    try {
      runInAction(() => {
        this.projectListStore.isLoading = true
        this.projectListStore.error = null
      })

      const params = this.getParams()
      const { data: { totalCount, payload } } = await getProjects(params)

      runInAction(() => {
        this.projectListStore.totalCount = totalCount
        this.projectListStore.projects = [ ...this.projectListStore.projects, ...payload ]
        this.changePage()
      })
    } catch (e) {
      this.projectListStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  async getById(id: string): Promise<Project | undefined> {
    try {
      runInAction(() => {
        this.projectListStore.isLoading = true
        this.projectListStore.error = null
      })

      const { data: { payload } } = await getProjectById(id)

      return payload
    } catch (err) {
      runInAction(() => {
        this.projectListStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  async create(data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.projectListStore.isLoading = true
        this.projectListStore.error = null
      })

      const { data: { status } } = await createProject(data)
      void this.load(true)

      return status
    } catch (err) {
      runInAction(() => {
        this.projectListStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  async update(id: string, data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.projectListStore.isLoading = true
        this.projectListStore.error = null
      })

      const { data: { status } } = await updateProject(id, data)
      void this.load(true)

      return status
    } catch (err) {
      runInAction(() => {
        this.projectListStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  async removeProject(id: string): Promise<void> {
    try {
      runInAction(() => {
        this.projectListStore.isLoading = true
        this.projectListStore.error = null
      })

      await removeProject(id)
      void this.load(true)

      runInAction(() => {
        this.projectListStore.projects = this.projectListStore.projects.filter((project) => project.id !== id)
        this.projectListStore.totalCount -= 1
      })
    } catch (err) {
      runInAction(() => {
        this.projectListStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.projectListStore.isLoading = false
      })
    }
  }

  setParam<KEY extends keyof ProjectListStore["params"], VALUE extends ProjectListStore["params"][KEY]>(key: KEY, value: VALUE): void {
    runInAction(() => {
      this.projectListStore.params[key] = value
    })

    void this.load(true)
  }

  private changePage(): void {
    if (this.projectListStore.totalCount > this.projectListStore.projects.length) {
      this.projectListStore.params.page += 1
    }
  }

  private getParams(): ProjectParams {
    const numberOfFloors = this.projectFiltersStore.numberOfFloors.size
      ? Array.from(this.projectFiltersStore.numberOfFloors).join("-or-")
      : undefined

    const area = this.projectFiltersStore.area.size
      ? Array.from(this.projectFiltersStore.area).join("-or-")
      : undefined

    return {
      ...this.projectListStore.params,
      numberOfFloors,
      area,
    }
  }

  constructor(
    private projectListStore: ProjectListStore,
    private projectFiltersStore: ProjectFiltersStore,
  ) {
  }
}

export const ProjectListService = new ProjectListServiceImpl(projectListStore, projectFiltersStore)
