import { runInAction } from "mobx"
import { makeObservableStore } from "../../../shared/utils"
import { CalculatorAreaKey, CalculatorBuildStart, CalculatorHouseType, CalculatorYard } from "../../service"

export interface CalculatorStore {
  isLoading: boolean
  error: string | null
  info: {
    houseType: keyof typeof CalculatorHouseType | undefined
    numberOfFloors: number | undefined
    components: {
      groundFloor: boolean
      garage: boolean
      terrace: boolean
      attic: boolean
    }
    area: CalculatorAreaKey | undefined
    yardSize: keyof typeof CalculatorYard | undefined
    buildStart: keyof typeof CalculatorBuildStart | undefined
  }
  currentStep: number
}

export const defaultCalculatorInfo: CalculatorStore["info"] = {
  houseType: undefined,
  numberOfFloors: undefined,
  components: {
    groundFloor: false,
    garage: false,
    terrace: false,
    attic: false,
  },
  area: undefined,
  yardSize: undefined,
  buildStart: undefined,
}

export const calculatorStore = makeObservableStore<CalculatorStore>({
  isLoading: false,
  error: null,
  info: defaultCalculatorInfo,
  currentStep: 1,
})

export function resetCalculatorStore() {
  runInAction(() => {
    calculatorStore.isLoading = false
    calculatorStore.error = null
    calculatorStore.currentStep = 1
    calculatorStore.info = defaultCalculatorInfo
  })
}
