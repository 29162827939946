import { umbrellaException } from "../../shared/lib/umbrellaException"
import { runInAction } from "mobx"
import { articleStore, ArticleStore } from "../store"
import {
  createArticle,
  getArticles,
  getArticleById,
  // toggleArticlePublish,
  updateArticle,
  removeArticle,
  toggleOnMain,
} from "shared/api/articles"
import { Article, ArticleCategory, ArticleParams } from "../../shared/types/article"
import { ArticleCategoryFilterStore, articleCategoryFilterStore } from "../store/article/ArticleCategoryFilterStore"

class ArticleServiceImpl {

  async load(controlledPagination?: boolean): Promise<void> {
    try {
      runInAction(() => {
        this.articleStore.articles = []
        this.articleStore.isLoading = true
        this.articleStore.error = null

        if (!controlledPagination) {
          this.articleStore.params.page = 1
        }
      })

      const params = this.getParams()
      const { data: { totalCount, payload } } = await getArticles(params)

      runInAction(() => {
        this.articleStore.totalCount = totalCount
        this.articleStore.articles = payload
        if (!controlledPagination) {
          this.changePage()
        }
      })
    } catch (e) {
      this.articleStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async loadMore(): Promise<void> {
    if (this.articleStore.isLoading) {
      return
    }

    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      const params = this.getParams()
      const { data: { totalCount, payload } } = await getArticles(params)

      runInAction(() => {
        this.articleStore.totalCount = totalCount
        this.articleStore.articles = [ ...this.articleStore.articles, ...payload ]
        this.changePage()
      })
    } catch (e) {
      this.articleStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async getOnMain(): Promise<void> {
    try {
      runInAction(() => {
        this.articleStore.articlesOnMain = []
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      const { data: { payload } } = await getArticles({ onMain: true })
      runInAction(() => {
        this.articleStore.articlesOnMain = payload
      })
    } catch (e) {
      this.articleStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async getById(id: string): Promise<Article | undefined> {
    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      const { data: { payload } } = await getArticleById(id)

      return payload
    } catch (err) {
      runInAction(() => {
        this.articleStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async create(data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      const { data: { status } } = await createArticle(data)
      void this.load(true)

      return status
    } catch (err) {
      runInAction(() => {
        this.articleStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async update(id: string, data: FormData): Promise<number | undefined> {
    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      const { data: { status } } = await updateArticle(id, data)
      void this.load(true)

      return status
    } catch (err) {
      runInAction(() => {
        this.articleStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async toggleOnMain(id: string, onMain: boolean): Promise<void> {
    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      await toggleOnMain(id, onMain)
      const articleIndex = this.articleStore.articles.findIndex(article => article.id === id)

      if (articleIndex > -1) {
        this.articleStore.articles[articleIndex].onMain = onMain
      }

    } catch (err) {
      runInAction(() => {
        this.articleStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  async remove(id: string): Promise<void> {
    try {
      runInAction(() => {
        this.articleStore.isLoading = true
        this.articleStore.error = null
      })

      await removeArticle(id)
      void this.load(true)

      runInAction(() => {
        this.articleStore.articles = this.articleStore.articles.filter((article) => article.id !== id)
        this.articleStore.totalCount -= 1
      })
    } catch (err) {
      runInAction(() => {
        this.articleStore.error = umbrellaException(err)
      })
    } finally {
      runInAction(() => {
        this.articleStore.isLoading = false
      })
    }
  }

  setParam<KEY extends keyof ArticleStore["params"], VALUE extends ArticleStore["params"][KEY]>(key: KEY, value: VALUE): void {
    runInAction(() => {
      this.articleStore.params[key] = value
    })

    void this.load(true)
  }

  toggleCategory(value: ArticleCategory): void {
    runInAction(() => {
      if (this.articleCategoryFilterStore.category.has(value)) {
        this.articleCategoryFilterStore.category.delete(value)
        return
      }

      this.articleCategoryFilterStore.category.add(value)
    })
  }

  private getParams(): ArticleParams {
    const categories = articleCategoryFilterStore.category.size
      ? Array.from(articleCategoryFilterStore.category).join("-or-")
      : undefined

    return {
      ...this.articleStore.params,
      categories,
    }
  }

  private changePage(): void {
    if (this.articleStore.totalCount > this.articleStore.articles.length) {
      this.articleStore.params.page += 1
    }
  }

  constructor(
    private articleStore: ArticleStore,
    private articleCategoryFilterStore: ArticleCategoryFilterStore,
  ) {
  }
}

export const ArticleService = new ArticleServiceImpl(articleStore, articleCategoryFilterStore)
