import {
  Anchor,
  createStyles,
  List,
  Modal,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core"
import { observer } from "mobx-react-lite"
import { DocModalStore } from "../../app/store"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 1024,
    overflow: "hidden",
    borderRadius: theme.radius.lg,
  },

  modalHeader: {
    padding: "32px 32px 24px",

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalBody: {
    padding: 32,

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalTitle: {
    fontWeight: 700,
    fontSize: 24,

    [theme.fn.smallerThan("md")]: {
      fontWeight: 600,
      fontSize: 20,
    },
  },
}))

const ExpressLink = () => {
  return (
    <>
      &nbsp;
      <Anchor href="https://stroiexpress.com/" target="_blank">
        stroiexpress.com
      </Anchor>
      &nbsp;
    </>
  )
}

export const PrivacyModal = observer(function PrivacyModal() {
    const { classes } = useStyles()
    const close = () => DocModalStore.setPrivacyOpened(false)

    return (
      <Modal.Root
        opened={DocModalStore.privacyIsOpened}
        onClose={close}
        zIndex={5000}
        centered
        scrollAreaComponent={ScrollArea.Autosize}
        size="auto"
      >
        <Modal.Overlay/>
        <Modal.Content className={classes.modalContent}>
          <Modal.Header className={classes.modalHeader}>
            <Modal.Title className={classes.modalTitle}>Политика конфиденциальности</Modal.Title>
            <Modal.CloseButton variant="light" radius="lg" size="xl"/>
          </Modal.Header>
          <Modal.Body className={classes.modalBody}>

            <Stack spacing="xl">

              <Stack spacing="md">
                <Text>
                  Политика в отношении обработки персональных данных
                </Text>

                <Text>
                  Общие положения
                </Text>
              </Stack>

              <Text>
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
                закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и
                меры по обеспечению безопасности персональных данных<ExpressLink/>(далее – Оператор).
              </Text>

              <Text>
                Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод
                человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность
                частной жизни, личную и семейную тайну.
              </Text>

              <Text>
                Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко
                всей информации, которую Оператор может получить о посетителях веб-сайта<ExpressLink/>
              </Text>

              <Text>
                Основные понятия, используемые в Политике
              </Text>

              <Text>
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
                закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и
                меры по обеспечению безопасности персональных данных<ExpressLink/>(далее – Оператор).
              </Text>

              <Text>
                Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
                вычислительной техники;
              </Text>

              <Text>
                Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением
                случаев, если обработка необходима для уточнения персональных данных);
              </Text>

              <Text>
                Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных,
                обеспечивающих их доступность в сети интернет по сетевому адресу<ExpressLink/>;
              </Text>

              <Text>
                Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных,
                и обеспечивающих их обработку информационных технологий и технических средств;
              </Text>

              <Text>
                Обезличивание персональных данных — действия, в результате которых невозможно определить без использования
                дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту
                персональных данных;
              </Text>

              <Text>
                Обработка персональных данных – любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования таких средств с персональными
                данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных;
              </Text>

              <Text>
                Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или
                совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также
                определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке,
                действия (операции), совершаемые с персональными данными;
              </Text>

              <Text>
                Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому
                Пользователю веб-сайта<ExpressLink/>;
              </Text>

              <Text>
                Пользователь – любой посетитель веб-сайта<ExpressLink/>
              </Text>

              <Text>
                Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному
                лицу или определенному кругу лиц;
              </Text>

              <Text>
                Распространение персональных данных – любые действия, направленные на раскрытие персональных данных
                неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
                неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации,
                размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным
                каким-либо иным способом;
              </Text>

              <Text>
                Трансграничная передача персональных данных – передача персональных данных на территорию иностранного
                государства органу власти иностранного государства, иностранному физическому или иностранному юридическому
                лицу;
              </Text>

              <Text>
                Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются
                безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной
                системе персональных данных и (или) результате которых уничтожаются материальные носители персональных
                данных.
              </Text>

              <Text>
                Оператор может обрабатывать следующие персональные данные Пользователя
              </Text>

              <List withPadding>
                <List.Item>Фамилия, имя, отчество</List.Item>
                <List.Item>Номера телефонов</List.Item>
              </List>

              <Text>
                Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с
                помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
              </Text>

              <Text>
                Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
              </Text>

              <Text>
                Цели обработки персональных данных
              </Text>

              <Text>
                Цель обработки персональных данных Пользователя — заключение, исполнение и прекращение гражданско-правовых
                договоров.
              </Text>

              <Text>
                Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных
                предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных
                сообщений, направив Оператору письмо на адрес электронной почты stroyexpress93@yandex.ru с пометкой «Отказ
                от уведомлениях о новых продуктах и услугах и специальных предложениях».
              </Text>

              <Text>
                Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора
                информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
              </Text>

              <Text>
                Правовые основания обработки персональных данных
              </Text>

              <Text>
                Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки
                Пользователем самостоятельно через специальные формы, расположенные на сайте<ExpressLink/>Заполняя
                соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое
                согласие с данной Политикой.
              </Text>

              <Text>
                Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках
                браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
              </Text>

              <Text>
                Порядок сбора, хранения, передачи и других видов обработки персональных данных
              </Text>

              <Text>
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
                закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и
                меры по обеспечению безопасности персональных данных<ExpressLink/>(далее – Оператор).
              </Text>

              <Text>
                Обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к
                персональным данным неуполномоченных лиц.
              </Text>

              <Text>
                Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за
                исключением случаев, связанных с исполнением действующего законодательства.
              </Text>

              <Text>
                В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
                самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора
                <ExpressLink/> с пометкой «Актуализация персональных данных».
              </Text>

              <Text>
                Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать
                свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной
                почты на электронный адрес Оператора<ExpressLink/>с пометкой «Отзыв согласия на обработку персональных
                данных».
              </Text>

              <Text>
                Трансграничная передача персональных данных
              </Text>

              <Text>
                Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что
                иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных,
                обеспечивается надежная защита прав субъектов персональных данных.
              </Text>

              <Text>
                Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
                вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме
                субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора,
                стороной которого является субъект персональных данных.
              </Text>

              <Text>
                Заключительные положения
              </Text>

              <Text>
                Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
                персональных данных, обратившись к Оператору с помощью электронной почты<ExpressLink/>
              </Text>

              <Text>
                В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
                Политика действует бессрочно до замены ее новой версией. Актуальная версия Политики в свободном доступе
                расположена в сети Интернет по адресу<ExpressLink/>
              </Text>

            </Stack>

          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)
