import axios from "axios"
import { type ErrorResponse } from "../types/common"

export function umbrellaException(err: unknown): string {
  if (axios.isAxiosError<ErrorResponse>(err) && err.response && err.response.data) {
    return err.response.data.message ?? "Непредвиденная ошибка"
  }

  return "Непредвиденная ошибка"
}
