import { runInAction } from "mobx"
import { FormModalStore, formModalStore } from "../store"
import { umbrellaException } from "../../shared/lib/umbrellaException"
import { sendCommonRequest } from "../../shared/api/crm"
import { CommonRequest } from "../../shared/types/crm"

class FormModalServiceImpl {

  async send(data: CommonRequest): Promise<void> {
    try {
      runInAction(() => {
        this.formModalStore.isLoading = true
        this.formModalStore.isSuccess = false
        this.formModalStore.error = null
      })

      await sendCommonRequest(data)

      runInAction(() => {
        this.formModalStore.isSuccess = true
      })
    } catch (e) {
      this.formModalStore.error = umbrellaException(e)
    } finally {
      runInAction(() => {
        this.formModalStore.isLoading = false
      })
    }
  }

  setIsOpened(state: boolean, topic?: string, tag?: string): void {
    runInAction(() => {
      this.formModalStore.isOpened = state
      this.formModalStore.topic = topic
      this.formModalStore.tag = tag
    })
  }

  constructor(
    private formModalStore: FormModalStore,
  ) {
  }
}

export const FormModalService = new FormModalServiceImpl(formModalStore)
